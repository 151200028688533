<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img class="fp_logo" :src="logo" alt="" style="object-fit: contain" />

        <h2 class="brand-text text-primary ml-1"></h2>
      </b-link>

      <b-col lg="7" class="d-none d-lg-flex p-5">
        <div class="w-100 d-lg-flex ">
          <b-img fluid :src="imgUrl" alt="Login V2" style="object-fit: cover"/>
        </div>
      </b-col>

      <b-col lg="5" class="d-flex align-items-center auth-bg px-0 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 pt-5 pt-xl-0 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Connect with Us and Unlock New Possibilities
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="login-email" v-model="form.email" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'ForgotPassword' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="form.password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="form.status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="Login" :disabled="isLoading">
                <b-spinner small v-if="isLoading" type="grow"></b-spinner>
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'Registration' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <b-row class="links-row">
            <b-col class="text-center">
              <b-link :to="{ name: 'Terms And Conditions' }">
                Terms & Conditions
              </b-link>
            </b-col>
            <b-col class="text-center">
              <b-link :to="{ name: 'Privacy Policy' }">
                Privacy Policy
              </b-link></b-col>
            <b-col class="text-center">
              <b-link :to="{ name: 'Cancellation' }">
                Cancellation and Refund policy
              </b-link></b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import SwitzLogo from "@/theme-variables/assets/Logo.png";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";
import { TokenService } from "@/apiServices/storageService";
import helpers from "@/libs/helpers";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        status: "",
        password: "",
        email: "",
      },
      sideImg: require("@/assets/images/pages/login/new-bg.jpg"),
      required,
      email,
      isLoading: false,
      logo: SwitzLogo,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login/new-bg.jpg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    Login() {
      this.isLoading = true;

      this.$refs.loginValidation.validate().then(async (success) => {
        if (success) {
          let payload = this.form;

          const location = await helpers.getLocation(navigator);

          if (location) {
            payload = {
              ...payload,
              latitude: location.coords.latitude,
              longitude: location.coords.longitude,
              accuracy: location.coords.accuracy,
            };
          }

          console.log("before login");

          AuthServices.Login(payload)
            .then((response) => {
              if (response.data.status) {
                TokenService.saveToken(response.data.data.token);
                this.$store.commit(
                  "user/SET_USER_DETAILS",
                  response.data.data.user
                );
                this.$store.commit("user/SET_USER_AUTHENTICATED", true);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || "Login Successful",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                const user = response.data.data.user;
                if (user.user_type === "student") {
                  this.$router.push("/home");
                } else {
                  this.$store.commit(
                    "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
                    true
                  );
                  this.$store.dispatch("user/onGetAssignedStudents");
                  if (
                    ["operations", "counselor", "visa"].includes(user.user_type)
                  ) {
                    this.$router.push("/staff_home");
                  } else if (
                    ["agent", "franchise", "student_support"].includes(
                      user.user_type
                    )
                  ) {
                    this.$router.push("/agent_home");
                  } else {
                    this.$router.push("/student_list");
                  }
                }

                this.isLoading = false;
              } else {
                this.isLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || "Login Failure",
                    icon: "EditIcon",
                    variant: "failure",
                  },
                });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              console.log("Error Login ", error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Server Error",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            });
        } else {
          this.isLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.links-row {
  position: absolute;
  bottom: -195px;
  right: 0;
  left: 0;
}
</style>
